<app-header></app-header>
<!---BRAND STORY1 START--->
<div class="brand-container">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="brand-content">
                    <h1>BRAND STORY</h1>
                    <h6></h6>
                </div>
            </div>
            <div class="col-md-8">
                <div class="brand-vector">
                    <img src="assets/img/Group1.png" width="500px" height="419px">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BRAND STORY1 END--->

<!---BRAND STORY2 START--->
<div class="brand-container">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="brand-vector2">
                    <img src="assets/img/mobile-vector.png">
                </div>
            </div>
            <div class="col-md-6">
                <div class="brand-content2">
                    <h5>“The App, Your Community”</h5>
                    <p>It all started with a simple idea. “Everyone Sells
                        Something.” So the creators came together to
                        build a C2C + social media platform for everyone
                        to be able to set up their own private shop, and
                        also bring together the surrounding as one big
                        community in your phone.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!---BRAND STORY2 END--->

<!---BRAND STORY3 START--->
<div class="brand-container">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="brand-vector3">
                    <img src="assets/img/brand-story3.png">
                </div>
            </div>
            <div class="col-md-6">
                <div class="brand-content3">
                    <h1>MeeKeT</h1>
                    <h6>Meerkat & The Market</h6>
                    <p>The name is derived from the combination of the
                        most social animal on the planet, the “meerkats” and “market”.
                        Known to always live in a large
                        community, meerkats always take care of each
                        other, looking out for another.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!---BRAND STORY3 END--->

<!---BRAND STORY4 START--->
<div class="brand-container4">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="brand-content4">
                    <h3>MEET THE MEEKET FAMILY</h3>
                    <h6>Meet the specialists behind the scenes who make it happen.</h6>
                    <div class="but"><a href="#" class="brand-button">MeeKeT</a></div>
                </div>
                <div class="brand-vector4">
                    <img src="assets/img/brand-vector4.png">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BRAND STORY4 END--->
<app-footer></app-footer>