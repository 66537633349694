import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient) { }

  getGuestToken() {
    const body = {
        request_type:"MEMBER", 
        device_id:"string", 
        device_type:"ANDROID", 
        device_token:"string",
        location:{type:"Point", coordinates:[80.25934953737513,13.029071455550344]}
    };
    let encoded = btoa(environment.appUserName+":"+environment.appPassword);
    const headers = { 'Authorization': 'Basic '+encoded, 'accept': 'application/json', 'Content-Type': 'application/json'};
    return this.http.post(`${environment.apiUrl}/api/v1.0/members/guest/token`, body, { headers })
    .pipe(map(user => {
        return user;
    }));
  }

  getPublicProfile(listId, token) {
      const headers = { 'Authorization': 'Bearer '+token };
      return this.http.get(`${environment.apiUrl}/api/v1.0/listings/${listId}`, { headers });
  }
}
