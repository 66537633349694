<section class="bg-light" (click)="gotoExternal()">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="row">
                    <div class="col-md-8">
                        <!--card start-->
                        <div class="bg-white rounded shadow-sm mb-4 mt-2">
                            <div class="row">
                                <div class="col-md-12 posting-item">
                                    <div class="connections-item-list p-3 border-0">
                                        <div class="connections-item-profile-pic">
                                            <img src="{{ users?.member?.profileImage }}" class="img-fluid border-0">
                                        </div>
                                        <div class="connections-item-profile-details ms-2">
                                            <p class="h6 pt-3 mb-0 fw-bold">{{ users?.member?.name }}</p>
                                        </div>
                                        <div class="connections-item-profile-follow pt-2">
                                            <div class="dropdown-container position-relative" tabindex="-1">
                                                <div class="three-dots"><small class="text-muted"></small>
                                                </div>
                                                <div class="dropdown-dot shadow-sm">
                                                    <ul class="list-group">
                                                        <li class="list-group-item"><a class="dropdown-item"><i
                                                                    class="bi bi-flag me-1"></i>Embeded
                                                                Post</a>
                                                        </li>
                                                        <li class="list-group-item"><a class="dropdown-item"><i
                                                                    class="bi bi-slash-circle me-1"></i>View
                                                                Hidden Replies</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center" *ngIf="users?.images[0]?.thumbnails !== null">
                                        <img src="{{ users?.images[0]?.thumbnails[0]?.url }}" class="img-fluid" alt="img">
                                    </div>
                                    <div class="text-center" *ngIf="users?.images[0]?.thumbnails === null">
                                        <img src="{{ users?.images[0]?.url }}" class="img-fluid" alt="img">
                                    </div>
                                    <div class="connections-item-list border-0 px-3">
                                        <div class="connections-item-profile-pic">
                                            <i class="bi bi-heart fs-2"></i>
                                        </div>
                                        <!---Like Tooltip Start--->
                                        <div class="connections-item-profile-pic">
                                            <p class="pt-2 mt-1 mx-2"><span class="tool"
                                                    data-tip=""
                                                    tabindex="1">{{ users?.likeCount }}</span>
                                            </p>
                                        </div>
                                        <!---Like Tooltip End--->

                                        <div class="connections-item-profile-pic">
                                            <i class="bi bi-chat-text fs-2 ms-2"></i>
                                        </div>
                                        <div class="connections-item-profile-pic">
                                            <p class="pt-2 mt-1 mx-2">{{ users?.commentCount }}</p>
                                        </div>
                                        <div class="connections-item-profile-pic">
                                            <i class="bi bi-share fs-2 ms-2"></i>
                                        </div>
                                        <div class="connections-item-profile-follow pt-1">
                                            <span class="me-2">{{ timeSince(users?.postedAt) }}</span><i class="bi bi-bookmark fs-4"></i>
                                        </div>
                                    </div>

                                    <p class="py-2 mb-2 px-3">{{ users?.description }}</p>

                                    <!--Comment Message list Start-->
                                    <div class="col-md-12 border-top p-2" *ngFor="let cmts of users?.comments;let i=index">
                                        <div class="com-body mb-2">
                                            <div class="d-flex bd-highlight">
                                                <div class="p-2 bd-highlight">
                                                    <img src="{{ cmts?.member?.profile_image }}" class="rounded-circle"
                                                        width="50px">
                                                </div>
                                                <div class="p-2 flex-fill bd-highlight">
                                                    <p class="fs-6 mb-0 fw-bold">{{ cmts?.member?.name }}</p>
                                                    <p class="mb-2"><small>{{ cmts?.comment }}</small>
                                                    </p>
                                                    <div class="w-100">
                                                        <span class="ms-1 text-muted"><small>{{ timeSince(cmts?.created_at) }}</small></span>
                                                        <span class="ms-1 text-muted"><small>{{ cmts?.like_count }} Likes</small></span>
                                                        <span class="ms-1 text-muted"><small>Reply</small></span>
                                                    </div>
                                                </div>
                                                <div class="p-2 flex-fill bd-highlight text-end pt-4 me-2">
                                                    <i class="bi bi-heart"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--Comment Message list End-->
                                </div>
                            </div>
                        </div>
                        <!--card end-->
                    </div>

                    <div class="col-md-4">
                        <div class="bg-white rounded p-2 px-3 shadow-sm suggestion-fixed-top mt-2">
                            <div class="row">
                                <div class="col text-center">
                                    <img src="assets/img/meeket-logo.png" class="img-fluid">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 text-center mt-3">
                                    <!--<form >-->
                                    <div class="mb-3 mt-3">
                                        <input type="email" class="form-control bg-grey" id="email"
                                            placeholder="Username or Phone Number" name="email" readonly>
                                    </div>
                                    <div class="mb-3">
                                        <input type="password" class="form-control bg-grey" id="pwd"
                                            placeholder="Password" name="pswd" readonly>
                                    </div>
                                    <button class="btn btn-warning text-dark fw-bold w-100" data-bs-toggle="modal"
                                        data-bs-target="#login">Log In</button>
                                    <!--</form>-->
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col text-start ms-2"><a class="text-primary"><small>Forgot
                                            Password?</small></a></div>
                                <div class="col text-end me-2"><a class="text-primary"><small>Guest
                                            Login</small></a></div>
                            </div>
                            <div class="row">
                            <div class="col-md-12 text-center my-2">______________ OR _____________</div>
                            <div class="col-md-12 text-center my-3">
                                <button class="btn btn-warning text-dark fw-bold w-100" data-bs-toggle="modal"
                                    data-bs-target="#login">Create Account</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>