<app-header></app-header>
<!---BANNER START--->
<div class="banner-container">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="contact-content">
                    <h1>CONTACT</h1>
                    <h6></h6>
                </div>
            </div>
            <div class="col-md-8">
                <div class="banner-vector">
                    <img src="assets/img/Group1.png" width="500px" height="419px">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BANNER END--->

<!---CONTACT START--->
<div class="container">
    <div class="contact-form">
        <div class="row">
            <div class="col-lg-6">
                <h4>Contact Us</h4>
                <p>Please provide some information to get started.</p>
                <form action="#" method="post">
                    <input class="form-control" name="name" placeholder="Name..." /><br />
                    <input class="form-control" name="email" placeholder="Email..." /><br />
                    <input class="form-control" name="phone" placeholder="Phone..." /><br />
                    <textarea class="form-control" name="text" placeholder="Message"
                        style="height:150px;"></textarea><br />
                    <button type="button" class="btn btn-warning">Submit</button><br /><br />
                </form>
            </div>
            <div class="col-lg-6">
                <div class="contact-text">
                    <h6>If you have any questions or troubleshoot queries<br> regarding the app, feel free to
                        contact us. The <br>MeeKet sure want to hear your thoughts.</h6>
                    <br /><br />
                    <h6>Phone:</h6>
                    <p class="text-warning">+1 (877) 747-9986</p>
                    <br /><br />
                    <h6>Email:</h6>
                    <p>info@meeket.vn</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!---CONTACT END--->
<app-footer></app-footer>