<div class="bottom-footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <a [routerLink]="'/'" target="_parent"><img src="../../assets/img/footer-logo.png" alt="MeeKet"
                        title="MeeKet"></a>
                <p>20 Collyer Quay, #09-01,</p>
                <p>Singapore, 049319, Singapore</p>
                <p>(+84) 1900-mee-ket,</p>
                <p>info@meeket.vn</p>
                <div class="social-media">
                    <a href="https://www.facebook.com/meeket.vn" target="_blank"><i
                            class="fa-brands fa-facebook"></i></a>
                    <a href="https://www.instagram.com/meeket.vn/" target="_blank"><i
                            class="fa-brands fa-instagram"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-google-plus-g"></i></a>
                    <a href="#" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                </div>
            </div>
            <div class="col-lg-3 footer-links">
                <ul>
                    <li><a [routerLink]="'/brand-story'" target="_parent">Brand Story</a></li>
                    <li><a [routerLink]="'/characters'" target="_parent">Characters</a></li>
                    <li><a [routerLink]="'/terms-and-conditions'" target="_parent">Terms of Use</a></li>
                </ul>
            </div>
            <div class="col-lg-2 footer-links">
                <ul>
                    <li><a [routerLink]="'/creators'" target="_parent">Creators</a></li>
                    <li><a [routerLink]="'/contact'" target="_parent">Contact</a></li>
                    <li><a [routerLink]="'/privacy-policy'" target="_parent">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-lg-2 justify-content-end">
                <img src="../../assets/img/hello.png" alt="location" title="location">
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-lg-12">
                <p class="copyright">@2021 MeeKet Vietnam. All rights reserved.</p>
            </div>
        </div>
    </div>
    <div class="bottom-logo"><img src="../../assets/img/bottom-logo.png" alt="location" title="location"></div>
</div>
<!---ANIMATION JS START--->
<script src="../../assets/js/aos.js"></script>
