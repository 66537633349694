import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brandstory',
  templateUrl: './brandstory.component.html',
  styleUrls: ['./brandstory.component.scss']
})
export class BrandstoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
