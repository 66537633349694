import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicPostComponentComponent } from './public-post-component/public-post-component.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrandstoryComponent } from './brandstory/brandstory.component';
import { CharactersComponent } from './characters/characters.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { CreatorsComponent } from './creators/creators.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { DeleteaccountComponent } from './deleteaccount/deleteaccount.component';
import { DarkdeleteaccountComponent } from './darkdeleteaccount/darkdeleteaccount.component';

@NgModule({
  declarations: [
    AppComponent,
    PublicPostComponentComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BrandstoryComponent,
    CharactersComponent,
    TermsconditionsComponent,
    CreatorsComponent,
    ContactComponent,
    PrivacypolicyComponent,
    DeleteaccountComponent,
    DarkdeleteaccountComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
