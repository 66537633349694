<app-header></app-header>
<!---PRIVACY POLICY START--->
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="default-content">
                <h4>MeeKet Privacy Policy</h4>
                <h5>Introduction</h5>
                <p>This policy (together with our Terms of Use applies to your use of:</p>
                <ul class="a">
                    <li>MeeKet mobile application software (App) hosted on the cloud via Amazon Web Services (App
                        Site) once you have downloaded or streamed a copy of the App onto your mobile telephone or
                        handheld device (Device).</li>
                    <li>Any of the services accessible through the App (Services) that are available on the App Site
                        or other sites of ours (Services Sites).</li>
                </ul>
                <p>This policy sets out the basis on which any personal data we collect from you, or that you
                    provide to us, will be processed by us. This App is not intended for children and we do not
                    knowingly collect data relating to children. Please read the following carefully to understand
                    our practices regarding your personal data and how we will treat it.</p>
                <h5>Important information and who we are</h5>
                <p>Meeket Pte Ltd is the controller and is responsible for your personal data (collectively referred
                    to as the "Company", "we", "us" or "our" in this policy). We have appointed a data protection
                    representative. If you have any questions about this privacy policy, please contact them using
                    the details set out below.</p>
                <h5>Information Collection and Use</h5>
                <p>For a better experience, while using our Service, we may require you to provide us with certain
                    personally identifiable information, including but not limited to location,. The information
                    that we request will be retained by us and used as described in this privacy policy.</p>
                <p>The app does use third-party services that may collect information used to identify you.</p>
                <p>Link to the privacy policy of third-party service providers used by the app</p>
                <ul class="b">
                    <li><a href="#" target="_blank">Google Play Services</a></li>
                    <li><a href="#" target="_blank">Google Analytics for Firebase</a></li>
                    <li><a href="#" target="_blank">Firebase crashlytics</a></li>
                </ul>
                <h5>Contact details</h5>
                <p>Our full details are:</p>
                <ul class="a">
                    <li>Full name of legal entity: Meeket Pte Ltd</li>
                    <li>Name of Data Protection Representative: Emmanuel Shin</li>
                    <li>Email address: honorcorp.com@gmail.com</li>
                    <li>Postal address: 20 Collyer Quay, #09-01, Singapore, 049319, Singapore</li>
                    <li>Telephone number: +82-1085766456</li>
                </ul>
                <p>You have the right to make a complaint at any time to the Information Commissioner's Office
                    (ICO), the Singapore supervisory authority for data protection issues.</p>
                <h5>Changes to the privacy policy and your duty to inform us of changes</h5>
                <p>We keep our privacy policy under regular review. It may change and if it does, these changes will
                    be posted on this page and, where appropriate, notified to you when you next start the App. The
                    new policy may be displayed on-screen and you may be required to read and accept the changes to
                    continue your use of the App or the Services. It is important that the personal data we hold
                    about you is accurate and current. Please keep us informed if your personal data changes during
                    our relationship with you.</p>
                <h5>Third party links</h5>
                <p>The App may, from time to time, contain links to and from the websites of our partner networks,
                    advertisers and affiliates. Please note that these websites and any services that may be
                    accessible through them have their own privacy policies and that we do not accept any
                    responsibility or liability for these policies or for any personal data that may be collected
                    through these websites or services, such as Contact and Location Data. Please check these
                    policies before you submit any personal data to these websites or use these services. The data
                    we collect about you we may collect, use, store and transfer different kinds of personal data
                    about you as follows:</p>
                <ul class="a">
                    <li>Identity Data.</li>
                    <li>Contact Data.</li>
                    <li>Financial Data.00+</li>
                    <li>Transaction Data.</li>
                    <li>Device Data.</li>
                    <li>Content Data.</li>
                    <li>Profile Data.</li>
                    <li>Usage Data.</li>
                    <li>Marketing and Communications Data.</li>
                    <li>Location Data.</li>
                </ul>
                <p>We explain these categories of data below.</p>
                <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any
                    purpose. Aggregated Data could be derived from your personal data but is not considered personal
                    data in law as this data will not directly or indirectly reveal your identity. For example, we
                    may aggregate your Usage Data to calculate the percentage of users accessing a specific App
                    feature. However, if we combine or connect Aggregated Data with your personal data so that it
                    can directly or indirectly identify you, we treat the combined data as personal data which will
                    be used in accordance with this privacy policy
                    We will only process Special Categories of Personal Data about you if you voluntarily place such
                    information in the course of using the App (this includes details about your race or ethnicity,
                    religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade
                    union membership, information about your health, and genetic and biometric data such as
                    fingerprints or facial biometrics). We do not collect any information about criminal convictions
                    and offences.</p>
                <h5>How is your personal data collected?</h5>
                <p>We will collect and process the following data about you:</p>
                <ul class="a">
                    <li>Information you give us. This is information (including Identity, Contact, Financial, and
                        Marketing and Communications Data) you consent to giving us about you by filling in forms on
                        the App Site or Service Sites (together our Sites) or by corresponding with us (for example,
                        by email or chat). It includes information you provide when register to use the App Site,
                        you download or register an App, subscribe to any of our Services, search for an App or
                        Service, make an in-App purchase, share data via an App's social media functions, enter a
                        competition, promotion or survey, and when you report a problem with the App, our Services,
                        or any of our Sites. If you contact us, we will keep a record of that correspondence.</li>
                    <li>Information we collect about you and your device. Each time you use the App or access our
                        Sites we will automatically collect personal data including Device, Content and Usage Data.
                        We collect this data using cookies and other similar technologies. Please see our cookie
                        policy below for further details.</li>
                    <li>Location Data. We also use GPS technology to determine your current location. Some of our
                        location-enabled Services require your personal data for the feature to work. If you wish to
                        use the particular feature, you will be asked to consent to your data being used for this
                        purpose. You can withdraw your consent at any time by disabling Location Data in your
                        settings. We will not pass your Location Data onto a third party (including those External
                        Third Parties identified in the Glossary below) unless it is strictly necessary to provide
                        the Services to you or to comply with a legal obligation imposed on Us.</li>
                    <li>Information we receive from other sources including third parties and publicly available
                        sources. We will receive personal data about you from various third parties as set out below
                    </li>
                    <li>Device Data from analytics providers such as Google Analytics and Big Query based outside
                        the EU;</li>
                    <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery
                        services such as Data Collect based inside the EU</li>
                    <li>Identity and Contact Data from Google accessing your Google login data;</li>
                    <li>Unique application numbers. When you want to install or uninstall a Service containing a
                        unique application number or when such a Service searches for automatic updates, that number
                        and information about your installation, for example, the type of operating system, may be
                        sent to us.</li>
                </ul>
                <h5>Cookies</h5>
                <p>We use cookies and/or other tracking technologies to distinguish you from other users of the App,
                    the App Site and the distribution platform (App store or Google play) and to remember your
                    preferences. This helps us to provide you with a good experience when you
                    use the App or when you use any of Our Sites and also allows us to improve the App and our
                    Sites. For detailed information on the cookies we use, the purposes for which we use them and
                    how you can exercise your choices regarding our use of your cookies, see our cookie policy
                    below.</p>
                <h5>How we use your personal data</h5>
                <p>We will only use your personal data when the law allows us to do so. Most commonly we will use
                    your personal data in the following circumstances:</p>
                <ul class="a">
                    <li>Where you have consented before the processing.</li>
                    <li>Where we need to perform a contract we are about to enter or have entered with you.</li>
                    <li>Where it is necessary for our legitimate interests (or those of a third party) and your
                        interests and fundamental rights do not override those interests.</li>
                    <li>Where we need to comply with a legal or regulatory obligation.</li>
                </ul>
                <p>See below to find out more about the types of lawful basis that we will rely on to process your
                    personal data. We will only send you direct marketing communications by email or text if we have
                    your consent. You have the right to withdraw that consent at any time by contacting us. We will
                    get your express opt-in consent before we share your personal data with any third party for
                    marketing purposes.</p>
                <h5>Purposes for which we will use your personal data</h5>
                <br>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Purpose/activity</th>
                            <th>Type of data</th>
                            <th>Lawful basis for processing</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>To install the App and register you as a new App user</td>
                            <td>Identity Contact Financial Device</td>
                            <td>Your consent Performance of a contract with you</td>
                        </tr>
                        <tr>
                            <td>To process in-App purchases and deliver Services including managing payments and
                                collecting money owed to us</td>
                            <td>Identity Contact Financial Transaction Device Marketing and Communications Location
                            </td>
                            <td>Your consentPerformance of a contract with youNecessary for our legitimate interests
                                (to recover debts due to us)</td>
                        </tr>
                        <tr>
                            <td>To manage our relationship with you including notifying you of changes to the App or
                                any Services</td>
                            <td>Identity Contact Financial Profile Marketing and Communications</td>
                            <td>Your consentPerformance of a contract with youNecessary for our legitimate interests
                                (to keep records updated and to analyse how customers use our products/
                                Services)Necessary to comply with legal obligations (to inform you of any changes to
                                our terms and conditions)</td>
                        </tr>
                        <tr>
                            <td>To enable you to participate in a prize draw, competition or complete a survey</td>
                            <td>Identity Contact Device Profile Marketing and Communications</td>
                            <td>Your consentPerformance of a contract with youNecessary for our legitimate interests
                                (to analyse how customers use our products/Services and to develop them and grow our
                                business)</td>
                        </tr>
                        <tr>
                            <td>To administer and protect our business and this App including troubleshooting, data
                                analysis and system testing</td>
                            <td>Identity Contact Device</td>
                            <td>Necessary for our legitimate interests (for running our business, provision of
                                administration and IT services, network security)</td>
                        </tr>
                        <tr>
                            <td>To deliver content and advertisements to youTo make recommendations to you about
                                goods or services which may interest youTo measure and analyse the effectiveness of
                                the advertising we serve youTo monitor trends so we can improve the App</td>
                            <td>Identity Contact Device Content Profile Usage Marketing and Communications Location
                            </td>
                            <td>ConsentNecessary for our legitimate interests (to develop our products/Services and
                                grow our business)</td>
                        </tr>
                        <tr>
                            <td>To enable you to use the Services</td>
                            <td>Content (Special Category)</td>
                            <td>Consent and Explicit Consent (Article 9 (2)(a))Processing relates to personal data
                                which has been made manifestly public by the data subject (Article (2) (e)</td>
                        </tr>
                    </tbody>
                </table>
                <h5>Disclosures of your personal data</h5>
                <p>When you consent to providing us with your personal data, we will also ask you for your consent
                    to share your personal data with the third parties set out below for the purposes set out in the
                    table Purposes for which we will use your personal data:</p>
                <ul class="a">
                    <li>External Third Parties as set out in the Glossary.</li>
                    <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our
                        assets. Alternatively, we may seek to acquire other businesses or merge with them. If a
                        change happens to our business, then the new owners may use your personal data in the same
                        way as set out in this privacy policy.</li>
                </ul>
                <h5>International transfers</h5>
                <p>Many of our external third parties are based outside the EEA so their processing of your personal
                    data will involve a transfer of data outside the EEA. Whenever we transfer your personal data
                    out of the EEA, we ensure a similar degree of protection is afforded to it by ensuring at least
                    one of the following safeguards is implemented:</p>
                <ul class="a">
                    <li>Where we use certain service providers, we may use specific contracts approved by the
                        European Commission which give personal data the same protection it has in Europe. For
                        further details, see European Commission: Model contracts for the transfer of personal data
                        to third countries.</li>
                    <li>Where we use providers based in the US, we may transfer data to them if they are part of the
                        Privacy Shield which requires them to provide similar protection to personal data shared
                        between Europe and the US. For further details, see European Commission: EU-US Privacy
                        Shield.</li>
                    <li>Where we transfer data to countries that are not deemed to provide an adequate level of
                        protection, we will anonymised any personal data before transferring the data to such
                        countries. Please contact us if you want further information on the specific mechanism used
                        by us when transferring your personal data out of the EEA.</li>
                </ul>
                <h5>Data security</h5>
                <p>All information you provide to us is stored on secure servers operated on the public cloud by
                    Amazon Web Services (AWS). Any payment transactions carried out by us or our chosen third-party
                    provider of payment processing services will be encrypted using SSL. Where we have given you (or
                    where you have chosen) a password that enables you to access certain parts of Our Sites, you are
                    responsible for keeping this password confidential. We ask you not to share a password with
                    anyone. Once we have received your information, we will use strict procedures and security
                    features to try to prevent your personal data from being accidentally lost, used or accessed in
                    an unauthorised way. We will collect and store personal data on your Device using application
                    data caches and browser web storage including HTML5 and other technology. Certain Services
                    include social networking, chat room or forum features. Ensure when using these features that
                    you do not submit any personal data that you do not want to be seen, collected or used by other
                    users. We have put in place procedures to deal with any suspected personal data breach and will
                    notify you and any applicable regulator when we are legally required to do so.</p>
                <h5>Data retention</h5>
                <p>Details of retention periods for different aspects of your personal data are available in our
                    retention policy which you can request by contacting us. By law we have to keep basic
                    information about our customers (including Contact, Identity, Financial and Transaction Data)
                    for six years after they cease being customers for tax purposes. We will only retain Location
                    Data for a maximum period of six months. In some circumstances you can ask us to delete your
                    data: see your legal rights below for further information. In some circumstances we will
                    anonymised your personal data (so that it can no longer be associated with you) for research or
                    statistical purposes, in which case we may use this information indefinitely without further
                    notice to you.</p>
                <h5>Your legal rights</h5>
                <p>Under certain circumstances you have the following rights under data protection laws in relation
                    to your personal data.</p>
                <ul class="a">
                    <li>Request access to your personal data.</li>
                    <li>Request correction of your personal data.</li>
                    <li>Request erasure of your personal data.</li>
                    <li>Object to processing of your personal data.</li>
                    <li>Request restriction of processing your personal data.</li>
                    <li>Request transfer or your personal data.</li>
                    <li>Right to withdraw consent.</li>
                </ul>
                <p>You also have the right to ask us not to continue to process your personal data for marketing
                    purposes. You can exercise any of these rights at any time by contacting us at info@meeket.vn.
                </p>
                <h5>Glossary</h5>
                <h5>Lawful basis</h5>
                <p>Consent means processing your personal data where you have signified your agreement by a
                    statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it
                    is a freely given, specific, informed and unambiguous indication of what you want. You can
                    withdraw your consent at any time by contacting us. Legitimate Interest means the interest of
                    our business in conducting and managing our business to enable us to give you the best
                    service/product and the best and most secure experience. We make sure we consider and balance
                    any potential impact on you (both positive and negative) and your rights before we process your
                    personal data for our legitimate interests. We do not use your personal data for activities
                    where our interests are overridden by the impact on you (unless we have your consent or are
                    otherwise required or permitted to by law). You can obtain further information about how we
                    assess our legitimate interests against any potential impact on you in respect of specific
                    activities by contacting us. Performance of Contract means processing your data where it is
                    necessary for the performance of a contract to which you are a party or to take steps at your
                    request before entering into such a contract. Comply with a legal obligation means processing
                    your personal data where it is necessary for compliance with a legal obligation that we are
                    subject to.</p>
                <h5>Third parties</h5>
                <h5>External third parties</h5>
                <p>Service providers acting as processors based in the UK and US who provide IT and system
                    administration services. Professional advisers acting as processors or joint controllers
                    including lawyers, bankers, auditors and insurers based in the UK and South Korea who provide
                    consultancy, banking, legal, insurance and accounting services. Regulators and other authorities
                    acting as processors or joint controllers based in the UK and South Korea who may require
                    reporting of processing activities in certain circumstances. Service providers acting as
                    processors based in the United States such as Google Analytics, Big Query, Apps flyer to provide
                    website and App analytics and analysis.</p>
                <h5>Your legal rights</h5>
                <p>You have the right to:</p>
                <ul class="a">
                    <li>Request access to your personal data (commonly known as a "data subject access request").
                        This enables you to receive a copy of the personal data we hold about you and to check that
                        we are lawfully processing it.</li>
                    <li>Request correction of the personal data that we hold about you. This enables you to have any
                        incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                        accuracy of the new data you provide to us.</li>
                    <li>Request erasure of your personal data. This enables you to ask us to delete or remove
                        personal data where there is no good reason for us continuing to process it. You also have
                        the right to ask us to delete or remove your personal data where you have successfully
                        exercised your right to object to processing (see below), where we may have processed your
                        information unlawfully or where we are required to erase your personal data to comply with
                        local law. Note, however, that we may not always be able to comply with your request of
                        erasure for specific legal reasons which will be notified to you, if applicable, at the time
                        of your request.</li>
                    <li>Object to processing of your personal data where we are relying on a legitimate interest (or
                        those of a third party) and there is something about your particular situation which makes
                        you want to object to processing on this ground as you feel it impacts on your fundamental
                        rights and freedoms. You also have the right to object where we are processing your personal
                        data for direct marketing purposes. In some cases, we may demonstrate that we have
                        compelling legitimate grounds to process your information which override your rights and
                        freedoms.</li>
                    <li>Request restriction of processing of your personal data. This enables you to ask us to
                        suspend the processing of your personal data in the following scenarios: (a) if you want us
                        to establish the data's accuracy; (b) where our use of the data is unlawful but you do not
                        want us to erase it; (c) where you need us to hold the data even if we no longer require it
                        as you need it to establish, exercise or defend legal claims; or (d) you have objected to
                        our use of your data but we need to verify whether we have overriding legitimate grounds to
                        use it.</li>
                    <li>Request the transfer of your personal data to you or to a third party. We will provide to
                        you, or a third party you have chosen, your personal data in a structured, commonly used,
                        machine-readable format. Note that this right only applies to automated information which
                        you initially provided consent for us to use or where we used the information to perform a
                        contract with you.</li>
                    <li>Withdraw consent at any time where we are relying on consent to process your personal data.
                        However, this will not affect the lawfulness of any processing carried out before you
                        withdraw your consent. If you withdraw your consent, we may not be able to provide certain
                        products or services to you. We will advise you if this is the case at the time you withdraw
                        your consent.</li>
                </ul>
                <h5>Description of categories of personal data</h5>
                <ul class="a">
                    <li>Identity Data: first name, last name, maiden name, username or similar identifier, marital
                        status, title, date of birth, gender, passport number, educational or professional
                        background, job title or function</li>
                    <li>Contact Data: billing address, delivery address, email address and mobile telephone numbers.
                    </li>
                    <li>Financial Data: bank account and payment card details and any other data necessary for
                        processing payments and fraud prevention including security code numbers and related billing
                        information</li>
                    <li>Transaction Data: includes details about payments to and from you and details of in-App
                        purchases.</li>
                    <li>Device Data: includes the type of mobile device you use, a unique device identifier (for
                        example, your Device's IMEI number, the MAC address of the Device's wireless network
                        interface, or the mobile phone number used by the Device), mobile network information, your
                        mobile operating system, the type of mobile browser you use, time zone setting, browser
                        plug-in types and versions, operating systems and platform.</li>
                    <li>Content Data: includes information stored on your Device, including contact lists, login
                        information, photos, videos or other digital content</li>
                    <li>Profile Data: includes your username and password, in-App purchase history, your interests,
                        preferences, feedback and survey responses.</li>
                    <li>Usage Data: includes details of your use of any of our Apps or your visits to any of Our
                        Sites including, but not limited to, traffic data and other communication data, whether this
                        is required for our own billing purposes or otherwise and the resources that you access.
                    </li>
                    <li>Marketing and Communications Data: includes your preferences in receiving marketing from us
                        and our third parties and your communication preference.</li>
                    <li>Location Data: includes your current location disclosed by GPS technology. We will use this
                        data only for the purpose of authenticating your location when you select a particular
                        market to enter.</li>
                    <li>Sensitive personal data: In the event you voluntarily or accidently reveal sensitive
                        information about you (that is your racial or ethnic origin, political opinions, religious
                        beliefs, trade union activities, physical or mental health, sex life or sexual orientation,
                        details or criminal offences or genetic or biometric data.</li>
                </ul>
                <h5>Cookie Policy</h5>
                <h5>What are cookies?</h5>
                <p>Cookies are very small text files that the server used to operate the website sends to the user’s
                    web browser. They are saved on your computer or mobile device. When you visit the website or
                    App, the server reads the contents of the cookies saved in your device.</p>
                <h5>What are cookies for?</h5>
                <p>MeeKet uses cookies that save and frequently retrieve the user’s information in order to:</p>
                <ul class="a">
                    <li>Recognise you when you re-visit Our Sites. This helps us to personalise our content and
                        information such as optimised advertisements. It also enables us to recognise your
                        preferences each time you visit.</li>
                    <li>Recognise how many visitors to Our Sites and visitor behaviour. This helps us to improve our
                        services and functionality. We use the following cookies:</li>
                    <li>Strictly necessary cookies. These are cookies that are required for the operation of our
                        website. They include, for example, cookies that enable you to log into secure areas of our
                        website, use a shopping cart or make use of e-billing services.</li>
                    <li>Analytical or performance cookies. These allow us to recognise and count the number of
                        visitors and to see how visitors move around our website when they are using it. This helps
                        us to improve the way our website works, for example, by ensuring that users are finding
                        what they are looking for easily.</li>
                    <li>Functionality cookies. These are used to recognise you when you return to our website. This
                        enables us to personalise our content for you, greet you by name and remember your
                        preferences (for example, your choice of language or region).</li>
                    <li>Targeting cookies. These cookies record your visit to our website, the pages you have
                        visited and the links you have followed. We will use this information to make our website
                        and the advertising displayed on it more relevant to your interests. We may also share this
                        information with third parties for this purpose.</li>
                </ul>
                <p>You can find more information about the individual cookies we use and the purposes for which we
                    use them in the table below:</p>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Cookie Title Cookie Name Type of Cookie</th>
                            <th>Purpose</th>
                            <th>Cookie Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Third Party Cookies Facebook Pixel_fbp</td>
                            <td>This Cookie is placed by Facebook. It enables MeeKet to measure, optimize and build
                                audiences for advertising campaigns served on Facebook. In particular it enables
                                MeeKet to see how our users move between devices when accessing the MeeKet web site
                                and Facebook, to ensure that MeeKet Facebook advertising is seen by our users most
                                likely to be interested in such advertising by analysing which content a user has
                                viewed and interacted with on the MeeKet web site. To opt-out please see
                                https://www.facebook.com/ads/preferences</td>
                            <td>3 Months</td>
                        </tr>
                    </tbody>
                </table>
                <p>Please note that the following third parties may also use cookies, over which we have no control.
                    These named third parties may include, for example, advertising networks and providers of
                    external services like web traffic analysis services. These third party cookies are likely to be
                    analytical cookies or performance cookies or targeting cookies:</p>
                <p>Facebook – For further information about the cookies which may be used by Facebook, please visit
                    Facebook’s privacy page https://en-gb.facebook.com/policies/cookies/</p>
                <p>To deactivate the use of third party advertising cookies, you may visit the consumer page of
                    Facebook to manage the use of these types of cookies.</p>
                <p>You can block cookies by activating the setting on your browser that allows you to refuse the
                    setting of all or some cookies. However, if you use your browser settings to block all
                    cookies (including essential cookies) you may not be able to access all or parts of our website.
                </p>
                <p>If using Internet Explorer -</p>
                <ul class="a">
                    <li>Internet Explorer: Tool Bar (top of the web browser) > Internet Options > Privacy > Settings
                    </li>
                </ul>
                <p>If using Chrome -</p>
                <ul class="a">
                    <li>Chrome: Settings (top right of the web browser) > “Advanced” at the bottom of the screen >
                        Privacy and Security > Site Settings > Cookies</li>
                </ul>
                <h5>Children’s Privacy</h5>
                <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case we discover that a
                    child under 13 has provided us with personal information, we immediately delete this from our
                    servers. If you are a parent or guardian and you are aware that your child has provided us with
                    personal information, please contact us so that we will be able to do the necessary actions.</p>
                <h5>Changes to This Privacy Policy</h5>
                <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                    periodically for any changes. We will notify you of any changes by posting the new Privacy
                    Policy on this page.<br>
                    This policy is effective as of 2021-12-18</p>
                <h5>CONTACT US</h5>
                <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to
                    contact us at <a href="#" target="_blank">info@meeket.vn</a> or meeketapp@gmail.com.</p>
            </div>
        </div>
    </div>
</div>
<!---PRIVACY POLICY END--->
<app-footer></app-footer>