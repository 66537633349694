<div class="nav-fixed-header">
    <nav class="navbar navbar-expand-sm bg-light navbar-light">
      <div class="container">
        <a class="navbar-brand" [routerLink]="'/'"><img src="../../assets/img/logo.png" alt="MeeKet" title="MeeKet"></a>
        <div class="justify-content-end" id="">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link text-dark" href="https://www.facebook.com/meeket.vn" target="_blank"><i class="fab fa-facebook-f"></i></a>
               </li> 
              <li class="nav-item">
              <a class="nav-link text-dark" href="https://www.instagram.com/meeket.vn/" target="_blank"><i class="fab fa-instagram"></i></a>
               </li> 
              <li class="nav-item">
              <a class="nav-link text-dark" href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
            </li>  
            <li class="nav-item">
              <a class="nav-link text-dark" href="https://play.google.com/store/apps/details?id=com.meekat.app"><span class="download-app">Download App</span></a>
            </li> 
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-dark" href="javascript:void(0)" role="button" data-bs-toggle="dropdown">EN</a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="javascript:void(0)">ENG</a></li>
                <li><a class="dropdown-item" href="javascript:void(0)">HIN</a></li>
                <li><a class="dropdown-item" href="javascript:void(0)">TAM</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
</div>