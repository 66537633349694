<app-header></app-header>
<!---BANNER START--->
<div class="banner-container">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="character-content">
                    <h1>CHARACTERS</h1>
                    <h6></h6>
                    <a href="#" class="banner-button">Check our characters</a>
                </div>
            </div>
            <div class="col-md-8">
                <div class="banner-vector">
                    <img src="assets/img/Group1.png" width="500px" height="419px">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BANNER END--->

<!---CHARACTERS START--->
<div class="container">
    <div class="characters">
        <div class="row">
            <div class="col-lg-4">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#1" onclick="openThumb('Thumb1')"></a>
                        <div class="thumbnail"><a href="#1" onclick="openThumb('Thumb1')">
                                <img src="assets/img/thumb1.png" alt="Characters" title="Characters"></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#2" onclick="openThumb('Thumb2')"></a>
                        <div class="thumbnail active"><a href="#2" onclick="openThumb('Thumb2')">
                                <img src="assets/img/thumb2.png" alt="Characters" title="Characters"></a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#3" onclick="openThumb('Thumb3')">
                            <div class="thumbnail">
                                <img src="assets/img/thumb3.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#4" onclick="openThumb('Thumb4')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#5" onclick="openThumb('Thumb5')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#6" onclick="openThumb('Thumb6')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#7" onclick="openThumb('Thumb7')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#8" onclick="openThumb('Thumb8')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#9" onclick="openThumb('Thumb9')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-xs-6 col-cha">
                        <a href="#10" onclick="openThumb('Thumb10')">
                            <div class="thumbnail">
                                <img src="assets/img/no-thumb.png" alt="Characters" title="Characters">
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="row d-flex justify-content-center">
                    <div class="col-lg-8">
                        <div class="thumbnail-large">
                            <div class="title">MeeKet Characters</div>
                            <div id="Thumb1" class="thumb" style="display:none"><img src="assets/img/large1.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb2" class="thumb"><img src="assets/img/large2.png" alt="Characters"
                                    title="Characters"></div>
                            <div id="Thumb3" class="thumb" style="display:none"><img src="assets/img/large3.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb4" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb5" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb6" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb7" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb8" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb9" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                            <div id="Thumb10" class="thumb" style="display:none"><img src="assets/img/large4.png"
                                    alt="Characters" title="Characters"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!---CHARACTERS END--->

<!---BOTTOM SECTION START--->
<div class="footer-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="footer-img">
                    <img src="assets/img/footer-mobile.png" alt="location" title="location">
                </div>
            </div>
            <div class="col-lg-8">
                <div class="footer-text">
                    <h5>DOWNLOAD THE APP</h5>
                    <p class="justify-content-center"></p>
                    <div class="icons">
                        <a href="https://apps.apple.com/in/app/meeket/id1623790091" target="_blank"><img src="../../assets/img/app-store.png" alt="location"
                            title="location"></a>&nbsp;&nbsp;
                        <a href="https://play.google.com/store/apps/details?id=com.meekat.app" target="_blank"><img src="../../assets/img/google-play.png" alt="location"
                            title="location"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!---BOTTOM SECTION END--->
<app-footer></app-footer>