<app-header></app-header>
<div class="bg-dark">
<section class="py-5">
    <div class="container pt-5 pb-5 mt-lg-3 mt-0">
        <div class="row">
            <div class="col-lg-12 text-center main-h1">
                <h1><span class="txt-clr text-white"> Delete Account </span></h1>
            </div>
            <div class="col-lg-12 pt-4">
                <div class="accordion" id="accordionabout">
                    <div class="accordion-item">
                        <h2 class="accordion-header bg-dark" id="heading28">
                            <button class="accordion-button bg-dark" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
                                <span class="text-white">How do I delete my Meeket account?</span>
                            </button>
                        </h2>
                        <div id="collapse28" class="accordion-collapse collapse show" aria-labelledby="heading28"
                            data-bs-parent="#accordionabout">
                            <div class="accordion-body bg-dark text-white">
                                To delete your Meeket account, you have two methods available. Firstly, you can send a
                                request to the support team via direct email to <a href="mailto:info@meeket.vn"
                                    class="a">info@meeket.vn</a>, expressing your intention to delete your account. Your
                                account will be deleted within 15 days from the date of your initial request.

                                <br>
                                <p></p> The second method involves using the Meeket App. Follow these steps: open the
                                app, access the burger menu, proceed to Settings, and then navigate to the Account
                                section. Choose the "Delete Account" option and follow the provided prompts. After
                                confirming your decision, you will receive an email containing a URL. Use this URL to
                                send an email confirming your account deletion.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
<app-footer></app-footer>