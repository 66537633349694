<app-header></app-header>
<!---TERMS AND CONDITIONS START--->
<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="default-content">
                <h4>Terms of Use</h4>
                <h6>INTRODUCTION</h6>
                <ul class="a list-group-numbered">
                    <li>Welcome to the MeeKet. These Terms of Use apply when you are using the website <a
                            href="https://www.meeket.vn/" target="_blank">www.meeket.vn</a> (“Website”) or you
                        intend to download the MeeKet mobile application (“App”). Please read these Terms of Use
                        carefully.</li>
                    <li>By downloading this App or by creating an account on the Website, you are agreeing to the
                        following terms, including those available by hyperlink, which are designed to ensure the
                        correct use of the App, Website and any associated services for everyone.</li>
                    <li>Your use of the App and Website and any of its features is also subject to the Privacy
                        Policy, Forbidden Items Policy and any other agreements applicable to you at <a
                            href="https://www.meeket.vn/" target="_blank">www.meeket.vn</a> these are collectively
                        known as the “Terms”. If you do not agree to these Terms, do not use the App or any of our
                        associated services.</li>
                    <li>In these Terms, “Service” means the service you connect via the App or the Website, and the
                        content we provide to you through it.</li>
                    <li>These Terms constitute a legally binding agreement between you and Meeket Pte Ltd (“MeeKet”,
                        “We” or “Us”) and are effective as of 18th December, 2021.</li>
                </ul>
                <p>The app does use third-party services that declare their Terms and Conditions.Link to Terms and
                    Conditions of third-party service providers used by the app</p>
                <ul class="b">
                    <li><a href="#" target="_blank">Google Play Services</a></li>
                    <li><a href="#" target="_blank">Google Analytics for Firebase</a></li>
                    <li><a href="#" target="_blank">Firebase crashlytics</a></li>
                </ul>
                <h6>LICENCE TO USE APP</h6>
                <p>We licence you to use:</p>
                <ul class="a list-group-numbered">
                    <li>The App and any updates or supplements to it;</li>
                    <li>Our app is made available free of charge</li>
                    <li>Any related online documentation. (“Documentation”); and</li>
                    <li>The Service as permitted in these Terms.</li>
                </ul>
                <h6>WEBSITE USE</h6>
                <ul class="a list-group-numbered">
                    <li>Our website is made available free of charge. We do not guarantee that our site, or any
                        content on it, will always be available or be interrupted. We may suspend or withdraw or
                        restrict the availability of all or any part of the Website for business and operational
                        reasons. We will try to give you reasonable notice of any suspension or withdrawal.</li>
                    <li>You are also responsible for ensuring that all persons who access our site through your
                        internet connection are aware of these Terms and that they comply with them.</li>
                    <li>We do not guarantee that the Website will be secure or free from bugs or viruses. You are
                        responsible for configuring your information technology, computer programmes and platform
                        and use your own virus protection software.</li>
                </ul>
                <h6>DESCRIPTION OF THE SERVICE</h6>
                <p>The type, detail, and service charge of the Service are as follows:</p>
                <ul class="a list-group-numbered">
                    <li>Type: mobile service providing local information</li>
                    <li>Service Name: MeeKet</li>
                    <li>Description: The Service provides users with the opportunity to join a local community
                        market which allows a user to post information in relation to second hand goods the user
                        wishes to buy or sell and shares the user’s location information in order to identify
                        potential local buyers and sellers. The Service also provides local lifestyle and
                        advertising.</li>
                    <li>Service Charge: There is no service charge for using the Service. However, this is subject
                        to clause 5 below.</li>
                </ul>
                <h6>SERVICE CHARGE</h6>
                <ul class="a list-group-numbered">
                    <li>The Service provided by us is either paid or free. Separate paid services can only be used
                        after paying the fee as indicated in the relevant service.</li>
                    <li>We may invoice a service charge for any paid Service in accordance with any method
                        established by an electronic payment service provider that entered into an agreement with us
                        or add it to the invoice designated by us to invoice the amount. We will notify you at the
                        time of the amount of the service charge and you will be able to review and accept the
                        charge before you use the service.</li>
                    <li>The cancellation or refund of the payment authorised by the use of the paid service shall be
                        governed by any terms notified to you prior to your purchase and relevant laws and
                        regulations.</li>
                    <li>Any request for refunds or for disclosure of personal information of the person who
                        authorised the payment on the grounds of personal identity theft or payment fraud will be
                        considered by us but we are not obliged to provide any such information on request unless
                        required by law.</li>
                    <li>Any data charge incurred by the use of wireless services is separate and is governed by the
                        policy of your mobile carrier. Any fees incurred when a thread is posted via such means as
                        MMS is governed by the policy of your mobile carrier.</li>
                </ul>
                <h6>YOUR PRIVACY</h6>
                <ul class="a list-group-numbered">
                    <li>Under data protection legislation, we are required to provide you with certain information
                        about who we are, how we process your personal data and for what purposes and your rights in
                        relation to your personal data and how to exercise them. This information is provided in
                        privacy policies and it is important that you read that information.</li>
                    <li>Please be aware that internet transmissions are never completely private or secure and that
                        any message or information you send using the App or any Service may be read or intercepted
                        by others, even if there is a special notice that a particular transmission is encrypted.
                    </li>
                    <li>By agreeing to these Terms, you warrant to us that in using the Service you will comply with
                        all applicable Data Protection laws and regulations.</li>
                </ul>
                <h6>APPSTORE’S TERMS ALSO APPLY</h6>
                <p>The ways in which you use the App and Documentation may also be controlled by the App store if
                    using an Apple device or Google play if using an android and their rules and policies will apply
                    instead of these Terms where there are differences between the two.</p>
                <h6>SUPPORT FOR THE APP AND HOW TO TELL US ABOUT PROBLEMS</h6>
                <ul class="a list-group-numbered">
                    <li>Contacting us (including with complaints). If you think the App or the Service are faulty or
                        misinterpreted or wish to contact us for any other reason please email our customer service
                        team at info@meeket.vn</li>
                    <li>How we will communicate with you. If we have to contact you we will do so by email, by SMS
                        or by pre-paid post, using the contact details you have provided to us.</li>
                </ul>
                <h6>HOW YOU MAY USE THE APP, INCLUDING HOW MANY DEVICES YOU MAY USE IT ON</h6>
                <p>The licence granted to you in Clause 2 is in consideration of your agreement to abide by these
                    Terms. This licence does not extend to a right to use any MeeKet trademark and logo. You are
                    solely responsible for all the information you submit to the App and any consequence that could
                    result from your submission.</p>
                <p>As a condition of using this App and Service, you may not:</p>
                <ul class="a list-group-numbered">
                    <li>Violate any of these Terms;</li>
                    <li>Import or export any product listed on the Service to or from a location outside;</li>
                    <li>Modify, copy, publish, license, sell or otherwise commercialise this App or any information
                        or software associated with this App;</li>
                    <li>Use this App in any manner that could impair any of our sites in a way or interfere with any
                        party’s use of enjoyment of any of our sites;</li>
                    <li>Otherwise transfer the App or the Service to someone else, whether for money, for anything
                        else or for free;</li>
                    <li>Copy the App, Documentation or Service, except as part of the normal use of the App or where
                        it is necessary for the purpose of back-up or operational security; and/or</li>
                    <li>Translate, merge, adapt, vary, alter or modify, the whole or any part of the App,
                        Documentation or Service nor permit the App or the Service or any part of them to be
                        combined with, or become incorporated in, any other programs, except as necessary to use the
                        App and the Service on devices as permitted in these Terms.</li>
                    <li>You must comply with any applicable third-party terms when using this App (for example your
                        wireless data service agreement or App store agreement).</li>
                </ul>
                <h6>ACCEPTABLE USE RESTRICTIONS</h6>
                <p>You must not:</p>
                <ul class="a list-group-numbered">
                    <li>Use the App or any Service in any unlawful manner, for any unlawful purpose, or in any
                        manner inconsistent with these Terms, or act fraudulently or maliciously, for example, by
                        hacking into or inserting malicious code, such as viruses, or harmful data, into the App,
                        any Service or any operating system;</li>
                    <li>Use the App or any Service to buy or sell any goods or services listed in MeeKet’s Forbidden
                        Items Policy which is available here;</li>
                    <li>Infringe our intellectual property rights or those of any third party in relation to your
                        use of the App or any Service (to the extent that such use is not licensed by these Terms);
                    </li>
                    <li>Transmit any material that is defamatory, offensive or otherwise objectionable in relation
                        to your use of the App or any Service;</li>
                    <li>Use the App or any Service in a way that could damage, disable, overburden, impair or
                        compromise our systems or security or interfere with other users; and</li>
                    <li>Collect or harvest any information or data from any Service or our systems or attempt to
                        decipher any transmissions to or from the servers running any Service.</li>
                </ul>
                <h6>NOTIFICATION</h6>
                <p>For the convenience of users, we may display a variety of information related to the use of the
                    Service, such as various notices and the promotion of our other services in our Service or send
                    them to the user's mobile phone as text messages.</p>
                <h6>YOU MUST BE 18 TO ACCEPT THESE TERMS</h6>
                <p>You must be 18 or over to accept these Terms, download the App and use our Service.</p>
                <h6>IF SOMEONE ELSE OWNS THE PHONE OR DEVICE YOU ARE USING</h6>
                <p>If you download or stream the App onto any phone or other device not owned by you, you must have
                    the owner’s permission to do so. You will be responsible for complying with these Terms, whether
                    or not you own the phone or other device.</p>
                <h6>WE MAY COLLECT TECHNICAL DATA ABOUT YOUR DEVICE</h6>
                <p>By using the App or any of the services we offer, you agree to us collecting and using technical
                    information about the devices you use the App on and related software, hardware and peripherals
                    to improve our products and to provide any service to you.</p>
                <h6>WE MAY COLLECT LOCATION DATA (BUT YOU CAN TURN LOCATION SERVICES OFF)</h6>
                <p>Our Service will need to make use of location data sent from your device. We will obtain location
                    information from a location information service provider that collects location information on
                    your device. We offer a service that allows users to become a member of a local community based
                    on their current location and upload posts related to the local community with other users.
                    Another service we provide is to provide lifestyle and advertising based on location. When
                    accessing the App on your device, you will receive a notification which will give you the choice
                    of whether to consent to our collection of location data. If you do not consent to us accessing
                    this data, then your use of the App and the Service we are able to provide will be affected. By
                    providing consent we and our affiliates’ and licensees’ may transmit, collect, retain, maintain,
                    process and use your location data and queries to provide and improve location-based and road
                    traffic-based products and services. For further information on how we collect and use location
                    data, please refer to our Privacy Policy.</p>
                <h6>WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES YOU LINK TO</h6>
                <ul class="a list-group-numbered">
                    <li>The App or any service we offer may contain links to other independent websites which are
                        not provided by us. Such independent sites are not under our control, and we are not
                        responsible for and have not checked and approved their content or their privacy policies
                        (if any).</li>
                    <li>You will need to make your own independent judgement about whether to use any such
                        independent sites, including whether to buy any products or services offered by them.</li>
                    <li>You agree not to hold us responsible for anything other users post or do.</li>
                </ul>
                <h6>INTELLECTUAL PROPERTY RIGHTS</h6>
                <ul class="a list-group-numbered">
                    <li>Any content you upload will be considered non confidential and non-proprietary. You retain
                        all of your ownership rights in your content, but you grant to us and other users of the App
                        and Service a limited licence to use, store, copy or modify that content and to distribute
                        and make available to third parties. The licence allows us to show posts in search results
                        of search engines (such as Google, Bing), to use posts in any on-line advertisement of the
                        App and Service including on Facebook and social media and to use posts for service notices
                        or operational content. We also have the right to disclose your identity to any third party
                        who is claiming that any content posted or uploaded by you constitutes a violation of their
                        intellectual property rights or of their right to privacy.</li>
                    <li>You may, at any time, request us to delete, exclude from search results or render the posts
                        private via the Customer Centre or by contacting the webmaster. You may withdraw your
                        consent at any time for us to use your posts for our own marketing purposes and this will
                        have no affect your continued use of the App and Service.</li>
                    <li>We have the right to remove any posts you make if, in our opinion, your post does not comply
                        with these Terms. You are solely responsible for securing and backing up your content.</li>
                    <li>You warrant that, where necessary, you have obtained the requisite permissions and consents
                        to:</li>
                    <li>use the intellectual property of another Service user or third-party; or</li>
                    <li>Make reference to another Service user or third-party in any content posted by you or any
                        person accessing the Service whilst logged into the App or Website.</li>
                </ul>
                <h6>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h6>
                <ul class="a list-group-numbered">
                    <li>We are responsible to you for foreseeable loss and damage caused by us. If we fail to comply
                        with these Terms, we are responsible for any loss or damage you suffer that is a foreseeable
                        result of our breaching these Terms or our failing to use reasonable care and skill, but we
                        are not responsible for any loss or damage that is not foreseeable. Loss or damage is
                        foreseeable if either it is obvious that it will happen or if, at the time you accepted
                        these Terms, both we and you knew it might happen.</li>
                    <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do
                        so. This includes liability for death or personal injury caused by our negligence or the
                        negligence of our employees, agents or subcontractors or for fraud or fraudulent
                        misrepresentation.</li>
                    <li>When we are liable for damage to your property. If defective digital content that we have
                        supplied damages a device or digital content belonging to you, we will either repair the
                        damage or pay you compensation. However, we will not be liable for damage that you could
                        have avoided by following our advice to apply an update offered to you free of charge or for
                        damage that was caused by you failing to correctly follow installation instructions or to
                        have in place the minimum system requirements advised by us.</li>
                    <li>We are not liable for business losses. The App and Service is for domestic and private use.
                        If you use the App or the Service for any commercial, business or resale purpose we will
                        have no liability to you for any loss of profit, loss of business, business interruption, or
                        loss of business opportunity.</li>
                    <li>Limitations to the App and the Service. The App and the Service are provided for general
                        information and entertainment purposes only. They do not offer advice on which you should
                        rely. You must obtain professional or specialist advice before taking, or refraining from,
                        any action on the basis of information obtained from the App or the Service. Although we
                        make reasonable efforts to update the information provided by the App and the Service, we
                        make no representations, warranties or guarantees, whether express or implied, that such
                        information is accurate, complete or up to date.</li>
                    <li>Check that the App and the Service are suitable for you. The App and the Service have not
                        been developed to meet your individual requirements. Please check that the facilities and
                        functions of the App and the Service (as described on the App store or Google play) meet
                        your requirements.</li>
                    <li>We are not responsible for events outside our control. If our provision of the Service or
                        support for the App or the Service is delayed by an event outside our control then we will
                        contact you as soon as possible to let you know and we will take steps to minimise the
                        effect of the delay. Provided we do this we will not be liable for delays caused by the
                        event but if there is a risk of substantial delay you may contact us to end your contract
                        with us and receive a refund for any services you have paid for but not received.</li>
                </ul>
                <h6>RELEASE</h6>
                <p>If you have a dispute with one or more users of the Service, you release us (and our officers,
                    directors, agents, subsidiaries, joint ventures and employees) from any and all claims, demands
                    and damages (actual and consequential) of every kind and nature, known or unknown, arising out
                    of or in any way connected with such disputes.</p>
                <h6>CHANGES TO THESE TERMS</h6>
                <ul class="a list-group-numbered">
                    <li>We may amend the Terms, the instructions on how to use the Service, and announcements in
                        order to reflect any changes in the law or the Service, etc. In the event we amend these
                        Terms, we shall display the amended items on the individual start-up screen of the App, and
                        subject to the below, the amended Terms shall become effective seven (7) days after the
                        notice has been given. Where the proposed amendments are substantial, the amended Terms
                        shall become effective thirty (30) days after the giving of notice.</li>
                    <li>If you do not accept the notified changes you may not be permitted to continue to use the
                        Service.</li>
                </ul>
                <h6>UPDATE TO THE APP AND CHANGES TO THE SERVICE</h6>
                <ul class="a list-group-numbered">
                    <li>In principle, the Service can be used throughout the year and twenty-four (24) hours a day.
                        However, we may suspend the Service on the grounds of work-related or technical matters, and
                        for a certain period of time set by us for the purpose of management.</li>
                    <li>The Service may be temporarily suspended for regular or temporary inspection for maintenance
                        and repair of equipment, or other significant reasons. In such an event, we will give prior
                        notice in the App delivery screen. If the Service is interrupted for reasons that cannot be
                        predicted, we will notify you immediately upon being made aware of the situation.</li>
                    <li>From time to time we may automatically update the App and change the Service to improve
                        performance, enhance functionality, reflect changes to the operating system or address
                        security issues. Alternatively, we may ask you to update the App for these reasons.</li>
                    <li>If you choose not to install such updates you may not be able to continue using the App and
                        the Service.</li>
                </ul>
                <h6>WE MAY END YOUR RIGHTS TO USE THE APP AND THE SERVICE IF YOU BREAK THESE TERMS</h6>
                <p>We may end your rights to use the App and Service at any time by contacting you if you have
                    breached these Terms in a serious way. We may restrict or suspend your use of the Service in the
                    event of each of the following:</p>
                <ul class="a list-group-numbered">
                    <li>You deliberately or negligently interfere with the management of the Service;</li>
                    <li>You falsified or provided any misleading information when registering with our Website or
                        when using the App;</li>
                    <li>If it is unavoidable due to the maintenance, repair, or construction of equipment for the
                        Service;</li>
                    <li>There is a disruption in the use of the Service due to an emergency, malfunctions in the
                        Service equipment, or the overload in the use of the Service;</li>
                    <li>We deem that continuing the provision of the Service is inappropriate on other serious
                        grounds;</li>
                    <li>Obstruction of the provision of the Service or access to the Service by using a method other
                        than that instructed by us;</li>
                    <li>Collection, use or disclosure of information of other users without permission;</li>
                    <li>Use of the Service for profit or publicity purposes;</li>
                    <li>Sending or posting information violating good public order or morals and any applicable law
                        such as obscene materials or information infringing on copyright or other form of
                        intellectual property;</li>
                    <li>Copying, modifying, distributing, selling, assigning, lending, providing as a security the
                        App or any associated service or part of the software included therein, or allowing another
                        person to engage in such use without our consent;</li>
                    <li>Reproducing, disassembling, imitating, or otherwise modifying the App, such as attempting to
                        reverse-engineer the software or extract the source code; or</li>
                    <li>Failure to comply with any relevant laws and regulations or these Terms. Where we restrict
                        or suspend the use of the Service due to the provisions of the paragraphs above, we shall
                        inform you as to the reasons why the suspension/restriction has occurred and the period of
                        suspension/restriction if possible.</li>
                </ul>
                <h6>TERMINATION OF THIS AGREEMENT (DE-REGISTRATION)</h6>
                <ul class="a list-group-numbered">
                    <li>If you no longer wish to use the Service, you may, at any given time, request the
                        termination of this agreement through the menu option. We will process the request in an
                        expeditious manner in accordance with the law. However, for the prevention of misuse of the
                        Service such as fraud, a user who is engaged in an on-going transaction or a
                        transaction-related dispute may be restricted from</li>
                    <li>On termination, all data such as the user’s information or the posts uploaded by the user
                        will be deleted excluding any information retained as required by law and in accordance with
                        our Privacy Policy or as per below. Posts uploaded by a user that are scrapped or otherwise
                        shared and displayed by a third party, or any comments posted by the user, engagements in
                        chats, or added posts to a third party’s post, will not be deleted but instead remain in our
                        Service within the scope necessary to allow the other users’ use of the Service in its
                        normal capacity.</li>
                </ul>
                <h6>WE MAY TRANSFER THIS AGREEMENT TO SOMEONE ELSE</h6>
                <p>We may transfer our rights and obligations under these Terms to another organisation. We will
                    always tell you in writing if this happens and we will ensure that the transfer will not affect
                    your rights.</p>
                <h6>YOU NEED OUR CONSENT TO TRANSFER YOUR RIGHTS TO SOMEONE ELSE</h6>
                <p>You may only transfer your rights or your obligations under these Terms to another person if we
                    agree in writing.</p>
                <h6>NO RIGHTS FOR THIRD PARTIES</h6>
                <p>This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) to
                    enforce any term of this agreement.</p>
                <h6>IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL CONTINUE IN FORCE</h6>
                <p>Each of the paragraphs of these Terms operates separately. If any court or relevant authority
                    decides that any of them are unlawful, the remaining paragraphs will remain in full force and
                    effect.</p>
                <h6>EVEN IF WE DELAY IN ENFORCING THIS CONTRACT, WE CAN STILL ENFORCE IT LATER</h6>
                <p>Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist
                    immediately that you do anything you are required to do under these Terms, or if we delay in
                    taking steps against you in respect of your breaking this contract, that will not mean that you
                    do not have to do those things and it will not prevent us taking steps against you at a later
                    date.</p>
                <h6>WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL PROCEEDINGS</h6>
                <p>These Terms are governed by English law and you can bring legal proceedings in respect of the
                    products in the English courts. If you live in Scotland you can bring legal proceedings in
                    respect of the products in either the Scottish or the English courts. If you live in Northern
                    Ireland you can bring legal proceedings in respect of the products in either the Northern Irish
                    or the English courts.</p>
                <h6>CONTACT US</h6>
                <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to
                    contact us at <a href="#" target="_blank">info@meeket.vn</a> or meeketapp@gmail.com.</p>
            </div>
        </div>
    </div>
</div>
<!---TERMS AND CONDITIONS END--->
<app-footer></app-footer>