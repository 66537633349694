import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicPostComponentComponent } from './public-post-component/public-post-component.component';
import { HomeComponent } from './home/home.component';
import { BrandstoryComponent } from './brandstory/brandstory.component';
import { CharactersComponent } from './characters/characters.component';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { CreatorsComponent } from './creators/creators.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { DeleteaccountComponent } from './deleteaccount/deleteaccount.component';
import { DarkdeleteaccountComponent } from './darkdeleteaccount/darkdeleteaccount.component';

const routes: Routes = [
  { path: '', component:  HomeComponent},
  { path: 'brand-story', component:  BrandstoryComponent},
  { path: 'characters', component:  CharactersComponent},
  { path: 'terms-and-conditions', component:  TermsconditionsComponent},
  { path: 'creators', component:  CreatorsComponent},
  { path: 'contact', component:  ContactComponent},
  { path: 'privacy-policy', component:  PrivacypolicyComponent},
  { path: 'post/:postid', component: PublicPostComponentComponent },
  { path: 'delete-account', component:  DeleteaccountComponent},
  { path: 'dark-delete-account', component:  DarkdeleteaccountComponent},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
