<app-header></app-header>
<!---BANNER START--->
<div class="banner-container" data-aos="zoom-in">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="banner-content">
                    <h1>MARKET AROUND YOU</h1>
                    <h6>Join the MeeKet family and connect to <br>everyone around you.</h6>
                </div>
            </div>
            <div class="col-md-8">
                <div class="banner-vector">
                    <img src="../../assets/img/Group1.png" width="500px" height="419px">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BANNER END--->
<!---TAB START--->
<div class="container">
    <div class="tab-container" data-aos="fade-up">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-2 col-mob">
                <a href="#01" onclick="openCity('London')">
                    <div class="tab-icon active" onclick="changeImg1(this)">
                        <span class="icon" id="1">
                            <img src="../../assets/img/Trade Fill.png" id="myimage1">
                        </span>
                        <h6 class="title text-dark">TRADE</h6>
                        <span class="badge">&nbsp;
                        </span>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 col-mob">
                <a href="#02" onclick="openCity('Paris')">
                    <div class="tab-icon" onclick="changeImg2(this)">
                        <span class="icon" id="2">
                            <img src="assets/img/Social.png" id="myimage2">
                        </span>
                        <h6 class="title text-dark">SOCIAL</h6>
                        <span class="badge">&nbsp;
                        </span>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 col-mob">
                <a href="#03" onclick="openCity('Tokyo')">
                    <div class="tab-icon" onclick="changeImg3(this)">
                        <span class="icon" id="3">
                            <img src="../../assets/img/Business.png" id="myimage3">
                        </span>
                        <h6 class="title text-dark">BUSINESS</h6>
                        <span class="badge">&nbsp;
                        </span>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 col-mob">
                <a href="#04" onclick="openCity('France')">
                    <div class="tab-icon" onclick="changeImg4(this)">
                        <span class="icon" id="4">
                            <img src="../../assets/img/Chat.png" id="myimage4">
                        </span>
                        <h6 class="title text-dark">CHAT</h6>
                        <span class="badge">&nbsp;
                        </span>
                    </div>
                </a>
            </div>
            <div class="col-lg-2 col-mob">
                <a href="#05" onclick="openCity('Germany')">
                    <div class="tab-icon" onclick="changeImg5(this)">
                        <span class="icon" id="5">
                            <img src="../../assets/img/Profile.png" id="myimage5">
                        </span>
                        <h6 class="title text-dark">PROFILE</h6>
                        <span class="badge">&nbsp;
                        </span>
                    </div>
                </a>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="tab-box">
                <div class="tab-contents">
                    <div class="tab-item">
                        <div id="London" class="w3-container city">
                            <div class="tab-image"><img src="../../assets/img/team1.png" alt="Team" title="Team"></div>
                            <div class="tab-right-text">
                                <p><img src="../../assets/img/Trade-big.png">
                                </p>
                                <h4>WHAT IS ‘TRADE’ ?</h4>
                                <h5>Buy</h5>
                                <p>Whether you’re looking for a specific product, or simply shopping around, you can
                                    find it here.</p>
                                <h5>Sell</h5>
                                <p>Everyone sells something. So make your own shop to showcase your products to
                                    people around you. Your listed product will be visible to everyone up to 10 km
                                    radius.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-item">
                        <div id="Paris" class="w3-container city" style="display:none">
                            <div class="tab-image"><img src="../../assets/img/team2.png" alt="Team" title="Team"></div>
                            <div class="tab-right-text">
                                <p><img src="../../assets/img/Social-big.png">
                                </p>
                                <h4>WHAT IS ‘SOCIAL’ ?</h4>
                                <h5>Nearby</h5>
                                <p>Socialize with the community around you. You never know how new friendships may
                                    start.</p>
                                <h5>Following</h5>
                                <p>It’s social media! Find people that you want to connect and be updated on their
                                    posts and products.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-item">
                        <div id="Tokyo" class="w3-container city" style="display:none">
                            <div class="tab-image"><img src="../../assets/img/team3.png" alt="Team" title="Team"></div>
                            <div class="tab-right-text">
                                <p><img src="../../assets/img/Business-big.png">
                                </p>
                                <h4>WHAT IS ‘BUSINESS’ ?</h4>
                                <h5>Personal User</h5>
                                <p>Whether it’s a food you’re craving or in need of some flowers, you can search
                                    businesses and shops nearby with a simple search.</p>
                                <h5>Business User</h5>
                                <p>As a business user, you can set-up your own business channel for other users
                                    nearby to search you.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-item">
                        <div id="France" class="w3-container city" style="display:none">
                            <div class="tab-image"><img src="../../assets/img/team4.png" alt="Team" title="Team"></div>
                            <div class="tab-right-text">
                                <p><img src="../../assets/img/Chat-big.png">
                                </p>
                                <h4>WHAT IS ‘CHAT’ ?</h4>
                                <h5>Buy</h5>
                                <p>You can chat with other users on buying and selling products, or connect with
                                    your favorite shop to receive promotions and coupons</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-item">
                        <div id="Germany" class="w3-container city" style="display:none">
                            <div class="tab-image"><img src="../../assets/img/team5.png" alt="Team" title="Team"></div>
                            <div class="tab-right-text">
                                <p><img src="../../assets/img/Profile-big.png">
                                </p>
                                <h4>WHAT IS ‘PROFILE’ ?</h4>
                                <h5>Post</h5>
                                <p>Share your daily activities with people nearby and your followers.</p>
                                <h5>Share</h5>
                                <p>Post your new and used products in your private shop.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!---TAB END--->
<!---PERSONAL START--->
<div class="container">
    <div class="personal-container" data-aos="zoom-in">
        <div class="row">
            <div class="col-lg-6">
                <div class="personal-text">
                    <h5>“Set-up Your Personal Area”</h5>
                    <p class="justify-content-center">From 1 km up to 10 km is your new community where you can
                        trade and socialize with other users and businesses.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="personal-img">
                    <img src="../../assets/img/personal-img.png" alt="personal" title="personal">
                </div>
            </div>
        </div>
    </div>
</div>
<!---PERSONAL END--->

<!---LOCATION START--->
<div class="container">
    <div class="location-container" data-aos="zoom-out">
        <div class="row">
            <div class="col-lg-6">
                <div class="location-img">
                    <img src="../../assets/img/location.png" alt="location" title="location">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="location-text">
                    <h5>“Be in Two Locations”</h5>
                    <p class="justify-content-center">You can utilize up to two locations: (1) Home, and (2)
                        Current. So, even when you’re outside, you can still connect with the community around your
                        home, or choose to enter a new community in your current location.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!---LOCATION END--->

<!---BOTTOM SECTION START--->
<div class="footer-image">
    <div class="container">
        <div class="mobile-container" data-aos="flip-up">
            <div class="row">
                <div class="col-lg-4">
                    <div class="footer-img">
                        <img src="../../assets/img/footer-mobile.png" alt="location" title="location">
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="footer-text">
                        <h5>READY TO JOIN YOUR COMMUNITY?</h5>
                        <p class="justify-content-center">We reconnect communities and offer an easy and sustainable
                            way for people living in the same area to trade and socialize with other users and
                            businesses.</p>
                        <div class="icons">
                            <a href="https://apps.apple.com/in/app/meeket/id1623790091" target="_blank"><img src="../../assets/img/app-store.png" alt="location"
                                    title="location"></a>&nbsp;&nbsp;
                            <a href="https://play.google.com/store/apps/details?id=com.meekat.app" target="_blank"><img src="../../assets/img/google-play.png" alt="location"
                                    title="location"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!---BOTTOM SECTION END--->
<app-footer></app-footer>