<app-header></app-header>
<!---BANNER START--->
<div class="banner-container">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="creator-content">
                    <h1>CREATORS</h1>
                    <h6>Imagine a meeting room packed with specialists
                        of each marketing discipline bouncing ideas back
                        to craft a truly customized strategy for one client.
                        and forth For us, this sort of passion and dedication
                        is a daily routine. Meet the team members working
                        behind the scenes.</h6>
                </div>
            </div>
            <div class="col-md-8">
                <div class="banner-vector">
                    <img src="assets/img/Group.png" width="500px" height="419px">
                </div>
            </div>
        </div>
    </div>
</div>
<!---BANNER END--->

<!---CREATORS START--->
<div class="container">
    <div class="creators-content">
        <div class="creators">
            <div class="row">
                <div class="col-lg-6">
                    <div class="creators-text">
                        <h2>Meet<br>
                            ‘The Team’</h2>
                        <p>Meet the specialists behind the scenes who <br>make it happen</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <img src="assets/img/creators1.png" alt="creators" title="creators">
                    <h5>Arjun Kalasalingam</h5>
                    <p>Founder & CFO</p>
                </div>
                <div class="col-lg-3">
                    <img src="assets/img/creators2.png" alt="creators" title="creators">
                    <h5>Emmanuel Shin</h5>
                    <p>Founder & Creative Director</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!---OUR OFFICE START--->
<div class="container">
    <div class="creators-content">
        <h4>Our office life</h4>
        <p></p>
        <div class="office box">
            <div class="row">
                <div class="col-lg-12">
                    <img src="assets/img/officenew1.png" alt="Our Office" title="Our Office">
                </div>
            </div>
        </div>
        <div class="office box">
            <div class="row">
                <div class="col-lg-8">
                    <img src="assets/img/officenew2.png" alt="Our Office" title="Our Office">
                </div>
                <div class="col-lg-4">
                    <img src="assets/img/officenew3.png" alt="Our Office" title="Our Office">
                </div>
            </div>
        </div>
    </div>
</div>
<!---OUR OFFICE END--->
<app-footer></app-footer>