import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { PostService } from '../services/post.service';

@Component({
  selector: 'app-public-post-component',
  templateUrl: './public-post-component.component.html',
  styleUrls: ['./public-post-component.component.scss']
})
export class PublicPostComponentComponent implements OnInit {

  users:any;
  isLoading = false;
  routeUrl:any;
  tokeRes: any;
  intervals:any = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 }
  ];

  constructor(
      private postServices: PostService,
      private router: Router,
      private route: ActivatedRoute,
  ) {
      this.routeUrl = this.router.url;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getProfile();
  }

  getProfile() {
      this.postServices.getGuestToken()
          .pipe(first())
          .subscribe({
              next: (users) => {
                  this.tokeRes = users;
                  this.postServices.getPublicProfile(this.route.snapshot.paramMap.get('postid'), this.tokeRes.bearerToken)
                      .pipe(first())
                      .subscribe({
                          next: (users) => {
                              this.users = users;
                              console.log(":::", this.users);
                          },
                          error: error => {
                              console.log(error);
                              this.isLoading = false;
                          }
                  });
              }
          })
  }

  timeSince(date) {
      date = new Date(date);
      const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
      const interval = this.intervals.find(i => i.seconds < seconds);
      const count = Math.floor(seconds / interval.seconds);
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  }

  gotoExternal() : void {
      if(
          navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') || 
          navigator.userAgent.includes('iPad Simulator') || navigator.userAgent.includes('iPhone Simulator') || 
          navigator.userAgent.includes('iPod Simulator') || navigator.userAgent.includes('iPod')
      ) {
          window.open("https://apps.apple.com/in/app/meeket/id1623790091", "_blank");
      } else if(navigator.userAgent.includes('Android')) {
          window.open("https://play.google.com/store/apps/details?id=com.meekat.app", "_blank");
      } else {
          window.open("https://play.google.com/store/apps/details?id=com.meekat.app", "_blank");
      }
  }

}
